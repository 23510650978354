import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Container, Grid2 as Grid, Grid2Props as GridProps } from "@mui/material";

import {
  Autocomplete,
  Blockquote,
  Group,
  List,
  Loader,
  NumberInput,
  Paper,
  rem,
  SegmentedControl,
  Select,
  Stepper,
  Text,
  TextInput,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconCar,
  IconCheck,
  IconCircleCheck,
  IconExclamationCircle,
  IconInfoCircle,
  IconPhoto,
  IconSelector,
  IconUpload,
  IconUser,
  IconX,
} from "@tabler/icons-react";

import ReCAPTCHA from "react-google-recaptcha";

import CustomMantineButton from "../../components/CustomMantineButton";

import {
  AppName,
  CarsAndModels,
  colors,
  Colors,
  Images,
  MinimumVINLength,
  years,
} from "../../lib/Data";

import { Endpoints } from "../../lib/Endpoints";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  getVINProperty,
  trimString,
  validateEmail,
  validatePhone,
} from "../../lib/Methods";
import { ImagePositions, SingleVINProperty, VinAPIResponse } from "../../lib/Types";
import { PerformRequest, UploadFiles } from "../../lib/usePerformRequest";

import "./styles.scss";

const Start: React.FC = () => {
  const navigate = useNavigate();
  const [formMode, setFormMode] = useState<"vin" | "manual">("vin");

  const [isVerifyingVIN, setVerifyingVIN] = useState<boolean>(false);
  const [shouldShowVINStatus, setShouldShowVINStatus] = useState<boolean>(false);
  const [isVINValid, setVINValid] = useState<boolean>(false);

  const [currentScreen, setCurrentScreen] = useState<
    "vehicle" | "advanced-vehicle" | "user" | "completed"
  >("vehicle");
  console.log(process.env);
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);

  useEffect(() => {
    switch (currentScreen) {
      case "vehicle":
        setCurrentScreenIndex(0);
        break;
      case "advanced-vehicle":
        setCurrentScreenIndex(0);
        break;
      case "user":
        setCurrentScreenIndex(1);
        break;
      case "completed":
        setCurrentScreenIndex(2);
        break;

      default:
        break;
    }
  }, [currentScreen]);
  const vinForm = useForm({
    initialValues: {
      vin: "",
    },
  });
  const [isRequesting, setRequesting] = useState<boolean>(false);

  const basicVehicleDetailsForm = useForm({
    initialValues: {
      manufacturer: "",
      model: "",
      year: "",
    },
    validate: {
      manufacturer: (value) => (value && value.length > 0 ? null : "Select Manufacturer"),
      model: (value) => (value && value.length > 0 ? null : "Enter vehicle model"),
      year: (value) =>
        value && value.toString().length > 0 ? null : "Enter vehicle year",
    },
  });
  const advancedVehicleDetailsForm = useForm({
    initialValues: {
      color: "",
      odometer: "",
      driveable: "",
    },
    validate: {
      odometer: (value) =>
        value && value.toString().length > 0 ? null : "Enter mileage",
      color: (value) => (value && value.length > 0 ? null : "Enter vehicle color"),
      driveable: (value) =>
        value && value.length > 0 ? null : "Specify vehicle condition",
    },
  });
  const userForm = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    validate: {
      firstName: (value) => (value.length >= 2 ? null : "Enter name"),
      lastName: (value) => (value.length >= 2 ? null : "Enter name"),
      email: (value) => (validateEmail(value) ? null : "Enter a valid email"),

      phone: (value) => (validatePhone(value) ? null : "Enter valid phone number"),
    },
  });
  const imagePositions: ImagePositions[] = ["front", "back", "left", "right"];

  const emptyTempImages: { position: ImagePositions; image: File | undefined }[] = [
    { position: "front", image: undefined },
    { position: "back", image: undefined },
    { position: "left", image: undefined },
    { position: "right", image: undefined },
  ];
  const [tempImages, setTempImages] =
    useState<{ position: ImagePositions; image: File | undefined }[]>(emptyTempImages);
  const [isContinueAllowed, setContinueAllowed] = useState<boolean>(false);

  const [vinResponse, setVinResponse] = useState<SingleVINProperty[]>([]);
  const ValidateVIN = async () => {
    const { hasErrors } = vinForm.validate();
    if (!hasErrors) {
      setVerifyingVIN(true);
      const r = await PerformRequest<VinAPIResponse>({
        method: "GET",
        route: Endpoints.GetVehicleDetailsWithVIN(vinForm.values.vin),
        callback: () => {
          setVerifyingVIN(false);
        },
      });
      setVerifyingVIN(false);
      if (r && r.status === 200) {
        setShouldShowVINStatus(true);
        if (getVINProperty("Model", r.data.Results)?.length > 0) {
          setVINValid(true);
          setVinResponse(r.data.Results);
          setFormMode("manual");
          setBackAllowed(true);
          let model = getVINProperty("Model", r.data.Results);
          let manufacturer = getVINProperty("Make", r.data.Results);
          model = model.toLowerCase();
          manufacturer = manufacturer.toLowerCase();

          const findCar = CarsAndModels.find(
            (c) => c.brand.toLowerCase() === manufacturer
          );
          const findModel = findCar?.models.find((m) => m.toLowerCase() === model);

          basicVehicleDetailsForm.setValues({
            year: getVINProperty("Model Year", r.data.Results),
            manufacturer: findCar?.brand ?? "",
            model: findModel ?? "",
          });

          setContinueAllowed(true);
        } else {
          setVINValid(false);
          setContinueAllowed(false);
          vinForm.setFieldError("vin", "VIN did not return any vehicle information");
        }
      } else {
        DefaultErrorNotification("Could not verify VIN! Please try again");
      }
      console.log(r);
    }
  };
  const CreateSaleRequest = async () => {
    const images = tempImages.map((i) => i.image);
    setRequesting(true);
    setBackAllowed(false);
    const rUpload = await UploadFiles(images as File[]);
    if (rUpload && rUpload.status === 201) {
      const { manufacturer, model, year } = basicVehicleDetailsForm.values;
      const { color, odometer, driveable } = advancedVehicleDetailsForm.values;
      const { vin } = vinForm.values;
      const { firstName, lastName, email, phone } = userForm.values;
      const r = await PerformRequest({
        method: "POST",
        route: Endpoints.GetCreateNewSaleRequest(),
        data: {
          fullname: `${trimString(firstName)} ${trimString(lastName)}`,
          email: trimString(email, true),
          manufacturer: trimString(manufacturer),
          model: trimString(model),
          odometer: trimString(odometer),
          year: trimString(year),
          phone: trimString(phone),
          color: trimString(color),
          driveable: driveable === "yes" ? true : false,
          vin: trimString(vin),
          images: rUpload.data.map((i) => i.path),
        },
        callback: () => {
          setBackAllowed(true);
          setRequesting(false);
        },
      });
      setBackAllowed(true);
      setRequesting(false);
      if (r) {
        if (r.status === 201) {
          DefaultSuccessNotification("Your request has been received!");
          setCurrentScreen("completed");

          setTempImages(emptyTempImages);
        }
        if (r.status === 403) {
          DefaultErrorNotification(
            (r.data as any) && (r.data as any)[0] ? (r.data as any)[0] : ""
          );
        }
      } else {
        DefaultErrorNotification("An error occurred!");
        setRequesting(false);
      }
    } else {
      setRequesting(false);
      DefaultErrorNotification("There was an error uploading images!");
    }
  };
  const Continue = () => {
    if (currentScreen === "vehicle") {
      if (formMode === "vin") {
        ValidateVIN();
      } else {
        const { hasErrors } = basicVehicleDetailsForm.validate();
        if (!hasErrors) {
          setCurrentScreen("advanced-vehicle");
        }
      }
    } else if (currentScreen === "advanced-vehicle") {
      const { hasErrors } = advancedVehicleDetailsForm.validate();
      const imagesError = tempImages.map((t) => t.image).includes(undefined);

      if (imagesError) {
        DefaultErrorNotification("Please select an image for each side of your vehicle!");
      }
      if (!hasErrors) {
        if (!imagesError) {
          setCurrentScreen("user");
        }
      }
    } else if (currentScreen === "user") {
      const { hasErrors } = userForm.validate();
      if (!hasErrors) {
        CreateSaleRequest();
      }
    }
  };

  const [isBackAllowed, setBackAllowed] = useState<boolean>(false);

  const GoBack = () => {
    if (currentScreen === "vehicle") {
      if (formMode === "manual") {
        setFormMode("vin");
      }
    } else if (currentScreen === "advanced-vehicle") {
      setCurrentScreen("vehicle");

      setFormMode("manual");
    } else if (currentScreen === "user") {
      setCurrentScreen("advanced-vehicle");
    }
  };
  // useEffect(() => {
  //   const { vin } = vinForm.values;
  //   if (vin && vin.length === MinimumVINLength) {
  //     ValidateVIN();
  //     console.log(vin);
  //   } else {
  //     setContinueAllowed(false);
  //   }
  // }, [vinForm.values.vin]);
  useEffect(() => {
    const isBackDisallowed = currentScreen === "vehicle" && formMode === "vin";
    setBackAllowed(!isBackDisallowed);
  }, [currentScreen, formMode]);
  const inputProps: any = {
    spellCheck: false,
    disabled: isRequesting,
    allowDeselect: false,
    searchable: true,
    w: "100%",
    mb: 20,
  };
  const gridItemProps: GridProps = {
    size: {
      xl: 3,
      lg: 3,
      md: 6,
      sm: 6,
      xs: 6,
    },
  };
  return (
    <>
      <Container maxWidth="md">
        <div className="start-container">
          <Text fz={30} c={Colors.BlackPrimary}>
            {(["vehicle", "advanced-vehicle"] as (typeof currentScreen)[]).includes(
              currentScreen
            )
              ? "Vehicle Information"
              : currentScreen === "user"
              ? "User Information"
              : "Success"}
          </Text>
          <Text fz={15} fw={500} c={Colors.GrayPrimary}>
            {(["vehicle", "advanced-vehicle"] as (typeof currentScreen)[]).includes(
              currentScreen
            )
              ? "Tell us about your vehicle"
              : currentScreen === "user"
              ? "Tell us about yourself"
              : "Your request has been received"}
          </Text>
          <Stepper
            my={20}
            active={currentScreenIndex}
            completedIcon={
              <IconCircleCheck style={{ width: rem(21), height: rem(21) }} />
            }
          >
            <Stepper.Step
              label="Vehicle"
              icon={
                <IconCar
                  color="var(--mantine-color-blue-filled)"
                  style={{ width: rem(21), height: rem(21) }}
                />
              }
            ></Stepper.Step>
            <Stepper.Step
              label="User"
              icon={
                <IconUser
                  color="var(--mantine-color-blue-filled)"
                  style={{ width: rem(21), height: rem(21) }}
                />
              }
            ></Stepper.Step>
            <Stepper.Step
              label="Completed"
              icon={
                <IconCheck
                  color="var(--mantine-color-blue-filled)"
                  style={{ width: rem(21), height: rem(21) }}
                />
              }
            ></Stepper.Step>
            <Stepper.Completed>
              Completed, click back button to get to previous step
            </Stepper.Completed>
          </Stepper>
          <Paper withBorder p={30}>
            <SegmentedControl
              display="none"
              value={formMode}
              data={[
                { label: "Use VIN", value: "vin" },
                { label: "Fill Details", value: "manual" },
              ]}
              onChange={(value) => setFormMode(value as typeof formMode)}
            />
            {currentScreen === "vehicle" ? (
              <>
                {formMode === "vin" ? (
                  <div className="flex-col">
                    <TextInput
                      my={20}
                      maxLength={MinimumVINLength}
                      {...vinForm.getInputProps("vin")}
                      classNames={{
                        input: "input",
                        wrapper: "input-wrapper",
                      }}
                      rightSection={
                        isVerifyingVIN ? (
                          <Loader size={15} />
                        ) : shouldShowVINStatus ? (
                          isVINValid ? (
                            <IconCircleCheck color={Colors.GreenPrimary} />
                          ) : (
                            <IconExclamationCircle color={Colors.RedPrimary} />
                          )
                        ) : (
                          <></>
                        )
                      }
                      label="VIN"
                      placeholder="Enter VIN"
                    />
                    <Text
                      style={{ cursor: "pointer" }}
                      mt={-10}
                      c={Colors.BluePrimary}
                      fz="sm"
                      onClick={() => {
                        setFormMode("manual");
                        setContinueAllowed(true);
                      }}
                    >
                      Skip
                    </Text>
                    {isVINValid && (
                      <div className="flex-row">
                        <div className="item flex-col">
                          <Text fw={500} fz={13} c={Colors.BluePrimary}>
                            Make
                          </Text>
                          <Text fw={500} fz={17}>
                            {getVINProperty("Make", vinResponse)}
                          </Text>
                          <div className="item flex-col">
                            <Text fw={500} fz={13} c={Colors.BluePrimary}>
                              Model
                            </Text>
                            <Text fw={500} fz={17}>
                              {getVINProperty("Model", vinResponse)}
                            </Text>
                          </div>

                          <div className="item flex-col">
                            <Text fw={500} fz={13} c={Colors.BluePrimary}>
                              Year
                            </Text>
                            <Text fw={500} fz={17}>
                              {getVINProperty("Model Year", vinResponse)}
                            </Text>
                          </div>
                        </div>
                        <img
                          // src={getCarImage(getVINProperty("Make", vinResponse))}
                          alt=""
                          className="image"
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex-col">
                    <Autocomplete
                      rightSection={<IconSelector size={15} />}
                      label="Manufacturer"
                      {...inputProps}
                      placeholder="Vehicle manufacturer"
                      {...basicVehicleDetailsForm.getInputProps("manufacturer")}
                      data={CarsAndModels.map((c) => {
                        return { label: c.brand, value: c.brand };
                      })}
                    />
                    <Autocomplete
                      rightSection={<IconSelector size={15} />}
                      label="Model"
                      {...inputProps}
                      placeholder="Select Model"
                      {...basicVehicleDetailsForm.getInputProps("model")}
                      data={CarsAndModels.find(
                        (c) => c.brand === basicVehicleDetailsForm.values.manufacturer
                      )?.models.map((model) => {
                        return { label: model, value: model };
                      })}
                    />
                    <Autocomplete
                      rightSection={<IconSelector size={15} />}
                      label="Year"
                      {...inputProps}
                      {...basicVehicleDetailsForm.getInputProps("year")}
                      placeholder="Vehicle year e.g 2001"
                      data={years.map((c) => {
                        return { label: c, value: c };
                      })}
                    />
                  </div>
                )}
              </>
            ) : currentScreen === "advanced-vehicle" ? (
              <div className="flex-col">
                <NumberInput
                  {...inputProps}
                  min={0}
                  hideControls
                  label="Mileage"
                  {...advancedVehicleDetailsForm.getInputProps("odometer")}
                  placeholder="Odometer reading"
                />
                <Autocomplete
                  rightSection={<IconSelector size={15} />}
                  label="Color"
                  {...inputProps}
                  placeholder="Color e.g Red"
                  {...advancedVehicleDetailsForm.getInputProps("color")}
                  data={colors.map((c) => {
                    return { label: c, value: c };
                  })}
                />
                <Select
                  {...inputProps}
                  searchable={false}
                  label="Is Vehicle Driveable"
                  placeholder="Is the Vehicle Drivable"
                  {...advancedVehicleDetailsForm.getInputProps("driveable")}
                  data={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                />
                <div className="media-container width-100">
                  <br />
                  <Text fw={600} fz={17} c={Colors.BlackPrimary}>
                    Upload Vehicle Images
                  </Text>
                  <Grid container spacing={1.2}>
                    {imagePositions.map((position) => {
                      const hasImage = tempImages.find((i) => i.position === position);
                      return (
                        <Grid {...gridItemProps} key={position}>
                          <div className="flex-row align-center">
                            <Text c="dimmed" tt="capitalize">
                              {position}
                            </Text>
                          </div>

                          <Dropzone
                            onDrop={(files) => {
                              const newImages = tempImages.map((i) => {
                                if (i.position === position) {
                                  return { position, image: files[0] };
                                } else {
                                  return i;
                                }
                              });
                              setTempImages(newImages);
                            }}
                            onReject={(files) => {
                              if (files && files[0]) {
                                DefaultErrorNotification(files[0]?.errors[0]?.message);
                              }
                            }}
                            maxFiles={1}
                            maxSize={5 * 1024 ** 2}
                            accept={IMAGE_MIME_TYPE}
                          >
                            {hasImage && hasImage.image ? (
                              <Paper
                                className="image"
                                style={{
                                  backgroundImage: `url(${URL.createObjectURL(
                                    hasImage.image
                                  )})`,
                                  cursor: isRequesting ? "progress" : "pointer",
                                }}
                              ></Paper>
                            ) : (
                              <Group
                                justify="center"
                                gap="xl"
                                h={70}
                                style={{ pointerEvents: "none" }}
                              >
                                <Dropzone.Accept>
                                  <IconUpload
                                    size={52}
                                    color="var(--mantine-color-blue-6)"
                                    stroke={1.5}
                                  />
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                  <IconX
                                    size={52}
                                    color="var(--mantine-color-red-6)"
                                    stroke={1.5}
                                  />
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                  <IconPhoto
                                    size={52}
                                    color="var(--mantine-color-dimmed)"
                                    stroke={1.5}
                                  />
                                </Dropzone.Idle>
                              </Group>
                            )}
                          </Dropzone>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            ) : currentScreen === "user" ? (
              <div className="flex-col">
                <TextInput
                  {...inputProps}
                  label="First Name"
                  {...userForm.getInputProps("firstName")}
                  placeholder="Enter first name"
                />
                <TextInput
                  {...inputProps}
                  label="Last Name"
                  {...userForm.getInputProps("lastName")}
                  placeholder="Enter last name"
                />
                <TextInput
                  {...inputProps}
                  label="Email"
                  {...userForm.getInputProps("email")}
                  placeholder="you@email.com"
                />
                <NumberInput
                  {...inputProps}
                  min={0}
                  leftSection={<Text px={4}>(+1)</Text>}
                  hideControls
                  label="Phone"
                  {...userForm.getInputProps("phone")}
                  placeholder="Your phone number"
                />
              </div>
            ) : (
              currentScreen === "completed" && (
                <div className="flex-col align-center completed">
                  <Text mb={0} ta="center" fw={600} fz={25} c={Colors.GreenSecondary}>
                    Submitted!
                  </Text>
                  <Text mt={-5} ta="center" fw={500} c={Colors.GraySecondary}>
                    Your request has been received and we will contact you shortly
                  </Text>
                  <img src={Images.RequestSentImage} alt="" className="image" />
                  <div className="flex-row align-center">
                    <CustomMantineButton
                      children="Go Home"
                      onClick={() => {
                        navigate("/");
                      }}
                      disabled={!isBackAllowed}
                      w={120}
                      color={Colors.BluePrimary}
                    />
                  </div>
                </div>
              )
            )}

            {currentScreen !== "completed" && (
              <>
                <div className="flex-col">
                  <div className="flex-row align-center">
                    <CustomMantineButton
                      children="Previous"
                      onClick={GoBack}
                      disabled={!isBackAllowed}
                      w={120}
                      mt={20}
                      mr={10}
                      leftSection={<IconArrowNarrowLeft />}
                      color={Colors.BluePrimary}
                    />
                    <CustomMantineButton
                      children={currentScreen === "user" ? "Submit" : "Continue"}
                      color={Colors.GreenPrimary}
                      onClick={Continue}
                      loading={isRequesting}
                      disabled={!isContinueAllowed}
                      w={140}
                      mt={20}
                      rightSection={<IconArrowNarrowRight />}
                    />
                  </div>
                </div>
              </>
            )}
          </Paper>

          {(["vehicle", "advanced-vehicle"] as (typeof currentScreen)[]).includes(
            currentScreen
          ) && (
            <div className="flex-col information-policy">
              <Text fw={600} fz={22} mt={20}>
                Information Policy
              </Text>
              <Text style={{ wordBreak: "break-word" }} lh={2}>
                At {AppName}, we value and respect your privacy. This Information Policy
                outlines how we collect, use, and protect the information you provide to
                us while using our services. Our goal is to ensure that your personal and
                vehicle information is handled securely and is only used to assess the
                worth of your car in connection with your trade-in request.
              </Text>

              <Text style={{ wordBreak: "break-word" }} fw={600} fz={18} mt={20}>
                What We Collect
              </Text>
              <List>
                <List.Item>
                  Personal Information: This includes your name, phone number, email
                  address
                </List.Item>
                <List.Item>
                  Vehicle Information: This includes details about the car you wish to
                  trade, such as:
                  <List>
                    <List.Item>Make and model</List.Item>
                    <List.Item>Year of manufacture</List.Item>
                    <List.Item>Vehicle Identification Number (VIN)</List.Item>
                    <List.Item>Mileage</List.Item>
                    <List.Item>Condition of the vehicle</List.Item>
                  </List>
                </List.Item>
              </List>
              <Text style={{ wordBreak: "break-word" }} fw={600} fz={18} mt={40}>
                How We Use Your Information
              </Text>
              <List>
                <List.Item>
                  Evaluate the condition and worth of the vehicle you wish to sell.
                </List.Item>
                <List.Item>
                  Provide you with an accurate offer for your used car.
                </List.Item>
                <List.Item>
                  Complete the necessary paperwork for the trade-in process.
                </List.Item>
                <List.Item>
                  Communicate with you regarding the status of your trade-in and payment.
                </List.Item>
                <Blockquote color="blue" icon={<IconInfoCircle />} mt="xl">
                  We will never use your personal or vehicle information for purposes
                  beyond the scope of this service. Your data will not be shared with any
                  third parties for marketing or other unrelated purposes.
                </Blockquote>
              </List>
              <Text style={{ wordBreak: "break-word" }} fw={600} fz={18} mt={40}>
                Data Retention
              </Text>
              <Text style={{ wordBreak: "break-word" }} lh={2}>
                Your personal and vehicle information will only be retained for as long as
                necessary to fulfill the purpose for which it was collected, or as
                required by law. Once your transaction is complete, your data will be
                securely archived or deleted, depending on the nature of the information.
              </Text>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};
export default Start;
