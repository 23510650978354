import {
  Button,
  Group,
  SimpleGrid,
  Textarea,
  TextInput,
  TextInputProps,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  DefaultErrorNotification,
  DefaultSuccessNotification,
  trimString,
  validateEmail,
} from "../../lib/Methods";

import "./styles.scss";
import { Colors } from "../../lib/Data";
import { Endpoints } from "../../lib/Endpoints";
import { PerformRequest } from "../../lib/usePerformRequest";
import { MessageFormProps } from "../../lib/Types";
import { useState } from "react";

export default function ContactUs() {
  const [isMessageSending, setMessageSending] = useState<boolean>(false);

  const messageForm = useForm<MessageFormProps>({
    initialValues: {
      name: "",
      email: "",
      message: "",
      subject: "",
    },
    validate: {
      name: (value) =>
        value && value.length > 1 && value.length < 400 ? null : "Enter your name",
      email: (value) =>
        validateEmail(value) && value.length < 400 ? null : "Enter a valid email",
      subject: (value) =>
        value && value.length > 5 && value.length < 400 ? null : "Enter message subject",
      message: (value) =>
        value && value.length > 5 && value.length < 400 ? null : "Enter your message",
    },
  });
  const SendMessageToAdmin = async () => {
    const { hasErrors } = messageForm.validate();
    if (!hasErrors) {
      const { name, email, subject, message } = messageForm.values;

      setMessageSending(true);
      const r = await PerformRequest({
        method: "POST",
        route: Endpoints.GetSendAdminMessage(),
        data: {
          name: trimString(name),
          email: trimString(email, true),
          message: trimString(message),
          subject: trimString(subject),
        },
        callback: () => {
          setMessageSending(false);
        },
      });
      setMessageSending(false);
      if (r) {
        if (r.status === 201) {
          DefaultSuccessNotification("Your message has been recorded!");
          messageForm.reset();
        } else {
          DefaultErrorNotification("Could not send message! Please try again");
        }
      } else {
        DefaultErrorNotification("An error occurred!");
      }
    }
  };

  const getInputProps = (type: string) => {
    const inputProps: TextInputProps = {
      variant: "filled",
      spellCheck: false,
      name: type,
      ...messageForm.getInputProps(type),
      styles: {
        input: {
          height: 55,
        },
      },
    };
    return inputProps;
  };

  return (
    <div id="contact" style={{ width: "100%", marginTop: "-60px" }}>
      <form className="contact-us-container" onSubmit={messageForm.onSubmit(() => {})}>
        <SimpleGrid cols={{ base: 1, sm: 2 }} mt="xl">
          <TextInput label="Name" placeholder="Your name" {...getInputProps("name")} />
          <TextInput label="Email" placeholder="Your email" {...getInputProps("email")} />
        </SimpleGrid>

        <TextInput
          label="Subject"
          placeholder="Subject"
          mt="md"
          {...getInputProps("subject")}
        />
        <Textarea
          mt="md"
          label="Message"
          placeholder="Your message"
          maxRows={10}
          minRows={5}
          autosize
          name="message"
          variant="filled"
          {...messageForm.getInputProps("message")}
        />

        <Group justify="center" mt="xl">
          <Button
            color={Colors.GreenPrimary}
            type="submit"
            className="btn"
            size="md"
            onClick={SendMessageToAdmin}
            loading={isMessageSending}
          >
            Send Message
          </Button>
        </Group>
        <div className="cars-bg"></div>
      </form>
    </div>
  );
}
